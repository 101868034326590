import type { ChakraProps, ThemingProps } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { RoutedTab } from './types';

import route, { parametersToObject } from 'lib/router/getRoute';

import TabsWithScroll from './TabsWithScroll';
import useTabIndexFromQuery from './useTabIndexFromQuery';

interface Props extends ThemingProps<'Tabs'> {
  tabs: Array<RoutedTab>;
  tabListProps?: ChakraProps | (({ isSticky, activeTabIndex }: { isSticky: boolean; activeTabIndex: number }) => ChakraProps);
  rightSlot?: React.ReactNode;
  rightSlotProps?: ChakraProps;
  stickyEnabled?: boolean;
  className?: string;
  onTabChange?: (index: number) => void;
}

const RoutedTabs = ({ tabs, tabListProps, rightSlot, rightSlotProps, stickyEnabled, className, onTabChange, ...themeProps }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = location.search;
  const queryParams = new URLSearchParams(queryString);
  const queryObject = parametersToObject(queryParams);
  const paramScrollToTab = queryParams.get('scroll_to_tabs');

  const tabIndex = useTabIndexFromQuery(tabs);
  const tabsRef = useRef<HTMLDivElement>(null);

  const handleTabChange = React.useCallback((index: number) => {
    const nextTab = tabs[index];

    navigate(route(
      { pathname: location.pathname, query: { ...queryObject, tab: nextTab.id } },
    ));

    onTabChange?.(index);
  }, [ tabs, location, onTabChange, navigate ]);

  useEffect(() => {
    if (paramScrollToTab) {
      tabsRef?.current?.scrollIntoView(true);
      // delete paramScrollToTab;
      navigate(route(
        {
          pathname: location.pathname,
          query: queryObject,
        }));
    }
  // replicate componentDidMount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabsWithScroll
      tabs={ tabs }
      tabListProps={ tabListProps }
      rightSlot={ rightSlot }
      rightSlotProps={ rightSlotProps }
      stickyEnabled={ stickyEnabled }
      onTabChange={ handleTabChange }
      defaultTabIndex={ tabIndex }
      { ...themeProps }
    />
  );
};

export default React.memo(chakra(RoutedTabs));
