import { Box, Image } from '@chakra-ui/react';
import makeBlockie from 'ethereum-blockies-base64';
import gradientAvatar from 'gradient-avatar';
import React from 'react';
import Jazzicon from 'react-jazzicon/dist/Jazzicon';

import config from 'configs/app';
import { randomIntFromSeed } from 'lib/randomIntFromSeed';
import IdenticonGithub from 'ui/shared/IdenticonGithub';

interface IconProps {
  hash: string;
  size: number;
}

const Icon = (props: IconProps): JSX.Element => {
  switch (config.UI.views.address.identiconType) {
    case 'github':
      return <IdenticonGithub size={ props.size } seed={ props.hash }/>;

    case 'blockie':
      const data = makeBlockie(props.hash);
      return <Image src={ data } alt={ `Identicon for ${ props.hash }}` }/>;

    case 'jazzicon':
      // eslint-disable-next-line react/display-name
      const seed = randomIntFromSeed(props.hash) * 10000;
      return (
        <Jazzicon
          diameter={ props.size }
          seed={ seed }
        />
      );

    case 'gradient_avatar':
      // eslint-disable-next-line react/display-name
      const svg = gradientAvatar(props.hash, props.size);
      return <div dangerouslySetInnerHTML={{ __html: svg }}/>;

    default:
      return <div/>;
  }
};

type Props = IconProps;

const AddressIdenticon = ({ size, hash }: Props) => {

  return (
    <Box boxSize={ `${ size }px` } borderRadius="full" overflow="hidden">
      <Icon size={ size } hash={ hash }/>
      {/* {img ? undefined : <Icon size={ size } hash={ hash }/>} */}
    </Box>
  );
};

export default React.memo(AddressIdenticon);
