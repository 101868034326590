import { useLocation } from 'react-router-dom';

import type { RoutedTab } from './types';

export default function useTabIndexFromQuery(tabs: Array<RoutedTab>) {
  const location = useLocation();
  const queryString = location.search;
  const queryParams = new URLSearchParams(queryString);
  const tabFromQuery = queryParams.get('tab');

  if (!tabFromQuery) {
    return 0;
  }

  const tabIndex = tabs.findIndex(({ id, subTabs }) => id === tabFromQuery || subTabs?.some((id) => id === tabFromQuery));

  if (tabIndex < 0) {
    return 0;
  }

  return tabIndex;
}
