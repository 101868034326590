async function getTokenIconUrl (chainID: string, addressHash: string) {
    let chainName = null
    switch (chainID) {
      case '1':
        chainName = 'ethereum'
        break
      case '99':
        chainName = 'poa'
        break
      case '100':
        chainName = 'xdai'
        break
      case '369':
        chainName = 'pulsechain'
        break
      case '943':
        chainName = 'pulsechain-testnet-v4'
        break
      default:
        chainName = null
        break
    }
    if (chainName) {
      if (await checkLink(`https://tokens.app.pulsex.com/images/tokens/${addressHash}.png`)) {
        return `https://tokens.app.pulsex.com/images/tokens/${addressHash}.png`
      }

      if (await checkLink(`https://tokens.app.v4.testnet.pulsex.com/images/tokens/${addressHash}.png`)) {
        return `https://tokens.app.v4.testnet.pulsex.com/images/tokens/${addressHash}.png`
      }

      if (await checkLink(`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${chainName}/assets/${addressHash}/logo.png`)) {
        return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${chainName}/assets/${addressHash}/logo.png`
      }

      if (await checkLink(`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${addressHash}/logo.png`)) {
        return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${addressHash}/logo.png`
      }
      return undefined
    } else {
      return undefined // '/images/icons/token_icon_default.svg'
    }
  }
  
  async function checkLink (url: string) {
    if (url) {
      try {
        const res = await fetch(url)
        return res.ok
      } catch (_error) {
        return false
      }
    } else {
      return false
    }
  }
  
  export { checkLink, getTokenIconUrl }