import { useColorModeValue, useToken, Box, chakra, Skeleton } from '@chakra-ui/react';
import React, { Suspense } from 'react';

const Identicon = React.lazy(() => import('react-identicons'));

interface Props {
  className?: string;
  size: number;
  seed: string;
}

const IdenticonGithub = ({ size, seed }: Props) => {
  const bgColor = useToken('colors', useColorModeValue('gray.100', 'white'));

  return (
    <Box
      boxSize={ `${ size * 2 }px` }
      transformOrigin="left top"
      transform="scale(0.5)"
      borderRadius="full"
      overflow="hidden"
    >
      <Suspense fallback={ <Skeleton w="100%" h="100%"/> }>
        <Identicon bg={ bgColor } string={ seed } size={ size * 2 }/>
      </Suspense>
    </Box>
  );
};

export default React.memo(chakra(IdenticonGithub));
