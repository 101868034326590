import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

export default function AppInitializer (): JSX.Element {
    const location = useLocation();
    const queryString = location.search;
    const navigate = useNavigate();

    const url = location.pathname + (queryString ?? '')
    useEffect(() => {
        navigate(url)
    }, [])

    return <span/>
}