export interface ReactRoute {
  pathname: string;
  query?: Record<string, string> | Partial<Record<string, string>>;
  queryString?: string;
}

export default function route({ pathname, query }: ReactRoute): string {
  let resultPath = pathname;

  if (query !== undefined && Object.keys(query).length > 0) {
    const remainingList = [];

    for (const key in query) {
      { /* eslint-disable-next-line no-prototype-builtins */ }
      if (query.hasOwnProperty(key) && resultPath.includes(`[${ key }]`)) {
        // Replace the placeholder with the actual value from the query object
        resultPath = resultPath.replace(`[${ key }]`, query?.[key] ?? '');
      } else {
        remainingList.push(key);
      }
    }

    if (remainingList.length > 0) {
      let i = 0;
      for (const key in query) {
        if (i === 0) {
          resultPath += '?';
        } else {
          resultPath += '&';
        }

        resultPath += key + '=' + query[key];
        i++;
      }
    }
  }

  // if (query !== undefined && Object.keys(query).length > 0) {
  //   let i = 0
  //   if (queryString !== undefined) {
  //     resultPath += queryString
  //   }
  //   for (const key in query) {
  //       // Replace the placeholder with the actual value from the query object
  //       if (query?.[key] !== undefined) {
  //         resultPath += (i === 0 && queryString === undefined ? "?" : "&") + key + "=" + encodeURIComponent(query?.[key] ?? '')
  //       }
  //   }
  // } else if (queryString !== undefined ) {
  //   resultPath += queryString
  // }

  return resultPath;
}

export function parametersToObject(urlParameters: URLSearchParams): Record<string, string> {
  if (!urlParameters) {
    return {};
  }
  const queryParamsObject: Record<string, string> = Array.from(urlParameters.entries()).reduce((obj, [ key, value ]) => {
    obj[key] = value;
    return obj;
  }, {});

  return queryParamsObject;
}
