import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

import { NETWORK_GROUPS } from 'types/networks';

import { featuredNetworks } from 'configs/app/featured_networks';

export default function useNetworkMenu() {
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();

  const data = featuredNetworks

  return React.useMemo(() => ({
    isOpen,
    onClose,
    onOpen,
    onToggle,
    data,
    availableTabs: NETWORK_GROUPS.filter((tab) => data?.some(({ group }) => group === tab)),
  }), [ isOpen, onClose, onOpen, onToggle, data ]);
}
