export function randomIntFromSeed(seed: string) {
  const hash = stringToHash(seed);

  const x = Math.sin(hash) * 10000;
  return x - Math.floor(x);
}

function stringToHash(string: string): number {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash;
}
