export const featuredNetworks = [{
		"title": "PulseChain",
		"url": "https://scan.pulsechain.com/",
		"group": "Mainnets",
		"isActive": true,
		"icon": "/icons/tokens/pls.svg",
		"invertIconInDarkMode": false
	},
	{
		"title": "PulseChain TestNet",
		"url": "https://scan.v4.testnet.pulsechain.com/",
		"group": "Testnets",
		"icon": "/icons/tokens/pls.svg",
		"invertIconInDarkMode": false
	},
	{
		"title": "PulseX",
		"url": "https://app.pulsex.com",
		"group": "Resources",
		"icon": "/icons/tokens/plsx.svg",
		"invertIconInDarkMode": false
	},
	{
		"title": "PulseChain Bridge",
		"url": "https://bridge.pulsechain.com",
		"group": "Resources",
		"icon": "/icons/tokens/pls.svg",
		"invertIconInDarkMode": false
	},
	{
		"title": "Validator Launchpad",
		"url": "https://launchpad.pulsechain.com",
		"group": "Resources",
		"icon": "/icons/tokens/pls.svg",
		"invertIconInDarkMode": false
	}
]
