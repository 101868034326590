import { MenuItem, Icon, chakra } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as iconPublicTags } from 'icons/publictags.svg';
import route from 'lib/router/getRoute';

interface Props {
  className?: string;
  hash: string;
  onBeforeClick: () => boolean;
}

const PublicTagMenuItem = ({ className, hash, onBeforeClick }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = React.useCallback(() => {
    if (!onBeforeClick()) {
      return;
    }

    navigate(route({ pathname: '/account/public-tags-request', query: { address: hash } }));
  }, [ hash, onBeforeClick, location, navigate ]);

  return (
    <MenuItem className={ className }onClick={ handleClick }>
      <Icon as={ iconPublicTags } boxSize={ 6 } mr={ 2 }/>
      <span>Add public tag</span>
    </MenuItem>
  );
};

export default React.memo(chakra(PublicTagMenuItem));
