import { getEnvValue } from './utils';
import { defineChain } from 'viem';

const DEFAULT_CURRENCY_DECIMALS = 18;

const chain = Object.freeze({
  id: getEnvValue('REACT_APP_NETWORK_ID'),
  name: getEnvValue('REACT_APP_NETWORK_NAME'),
  shortName: getEnvValue('REACT_APP_NETWORK_SHORT_NAME'),
  currency: {
    name: getEnvValue('REACT_APP_NETWORK_CURRENCY_NAME'),
    symbol: getEnvValue('REACT_APP_NETWORK_CURRENCY_SYMBOL'),
    decimals: Number(getEnvValue('REACT_APP_NETWORK_CURRENCY_DECIMALS')) || DEFAULT_CURRENCY_DECIMALS,
  },
  governanceToken: {
    symbol: getEnvValue('REACT_APP_NETWORK_GOVERNANCE_TOKEN_SYMBOL'),
  },
  rpcUrl: getEnvValue('REACT_APP_NETWORK_RPC_URL'),
  isTestnet: getEnvValue('REACT_APP_IS_TESTNET') === 'true',
  verificationType: getEnvValue('REACT_APP_NETWORK_VERIFICATION_TYPE') || 'mining',
});

export default chain;

export const pulsechain = defineChain({
  id: 369,
  network: 'pulsechain',
  name: 'PulseChain',
  nativeCurrency: { name: 'Pulse', symbol: 'PLS', decimals: 18 },
  testnet: false,
  rpcUrls: {
    default: {
      http: ['https://rpc.pulsechain.com'],
      webSocket: ['wss://ws.pulsechain.com']
    },
    public: {
      http: ['https://rpc.pulsechain.com'],
      webSocket: ['wss://ws.pulsechain.com']
    }
  },
  blockExplorers: {
    default: {
      name: 'PulseScan',
      url: 'https://scan.pulsechain.com'
    }
  },
  contracts: {
    ensRegistry: {
      address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
    },
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 14353601
    }
  }
})
