// https://hexdocs.pm/phoenix/js/
import type { SocketConnectOption } from 'phoenix';
import { Socket } from 'phoenix';
import React, { useEffect, useState } from 'react';

export const SocketContext = React.createContext<Socket | null>(null);

interface SocketProviderProps {
  children: React.ReactNode;
  url?: string;
  options?: Partial<SocketConnectOption>;
  pingMessage?: string
}

export function SocketProvider({ children, options, url, pingMessage }: SocketProviderProps) {
  const [ socket, setSocket ] = useState<Socket | null>(null);

  useEffect(() => {
    if (!url) {
      return;
    }

    const socketInstance = new Socket(url, options);
    socketInstance.connect();

    const intervalId = setInterval(() => {
      if (socketInstance?.isConnected()) {
        socketInstance.push({ topic: "ping", event: "heartbeat", payload: {}, ref: null });
      } else if (socketInstance) {
        socketInstance.connect();
      } else if (!socketInstance) {
        const socketInstance = new Socket(url, options);
        socketInstance.connect();
        setSocket(socketInstance);
      }
    }, 5000);

    setSocket(socketInstance);

    return () => {
      clearInterval(intervalId);
      socketInstance.disconnect();
      setSocket(null);
    };
  }, [ options, url ]);

  return (
    <SocketContext.Provider value={ socket }>
      { children }
    </SocketContext.Provider>
  );
}

export function useSocket() {
  const context = React.useContext(SocketContext);
  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
}
