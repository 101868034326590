import { useLocation } from 'react-router';

import config from 'configs/app';
import route from 'lib/router/getRoute';

const feature = config.features.account;

export default function useLoginUrl() {
  const router = useLocation();

  return feature.isEnabled ?
    feature.authUrl + route({ pathname: '/auth/auth0', query: { path: router.pathname } }) :
    undefined;
}
