/* Optional User Login & API Key pages */
// import Login from 'ui/pages/Login';
// import ApiKeys from 'ui/pages/ApiKeys'
// import CustomAbiPage from 'ui/pages/CustomAbi'
// import PublicTags from 'ui/pages/PublicTags'
// import PrivateTags from 'ui/pages/PrivateTags'
// import VerifiedAddresses from 'ui/pages/VerifiedAddresses'
// import Watchlist from 'ui/pages/Watchlist'

/* Optional User Login & API Key routes */
// import L2Deposits from 'ui/pages/L2Deposits'
// import L2TxnBatches from 'ui/pages/L2TxnBatches'
// import L2OutputRoots from 'ui/pages/L2OutputRoots'
// import L2Withdrawals from 'ui/pages/L2Withdrawals'

import Home from 'ui/pages/Home'
import Accounts from 'ui/pages/Accounts'
import Address from 'ui/pages/Address'
import Block from 'ui/pages/Block'
import Blocks from 'ui/pages/Blocks'
import CsvExport from 'ui/pages/CsvExport';
import ContractVerification from 'ui/pages/ContractVerification'
import Tokens from 'ui/pages/Tokens'
import Token from 'ui/pages/Token'
import TokenInstance from 'ui/pages/TokenInstance'
import Transaction from 'ui/pages/Transaction'
import Transactions from 'ui/pages/Transactions'
import KettleTxs from 'ui/pages/KettleTxs'
import GraphQL from 'ui/graphQL/GraphQL'
import Stats from 'ui/pages/Stats'
import SearchResults from 'ui/pages/SearchResults'
import VerifiedContracts from 'ui/pages/VerifiedContracts'
import Withdrawals from 'ui/pages/Withdrawals'
import Apps from 'ui/pages/Marketplace'
import MarketPlaceApp from 'ui/pages/MarketplaceApp'
import SwaggerUI from 'ui/apiDocs/SwaggerUI';
import Sol2Uml from 'ui/pages/Sol2Uml';
import AppError from 'ui/shared/AppError/AppError'

import LayoutDefault from 'ui/shared/layout/Layout'
import LayoutHome from 'ui/shared/layout/LayoutHome'
import LayoutSearchResults from 'ui/shared/layout/LayoutSearchResults'
import LayoutError from 'ui/shared/layout/LayoutError'
import AppRedirect from './components/AppRedirect'

export const routes = [
    /* Optional User Login & API Key routes */
    // { path: '/login', component: Login, layout: LayoutDefault },
    // { path: '/accounts/api-key', component: ApiKeys, layout: LayoutDefault },
    // { path: '/accounts/custom-abi', component: CustomAbiPage, layout: LayoutDefault },
    // { path: '/accounts/public-tags-request', component: PublicTags, layout: LayoutDefault },
    // { path: '/accounts/tag-address', component: PrivateTags, layout: LayoutDefault },
    // { path: '/accounts/verified-addresses', component: VerifiedAddresses, layout: LayoutDefault },
    // { path: '/accounts/watchlist', component: Watchlist, layout: LayoutDefault },

    /* Optional L2 Routes */
    // { path: '/l2-deposits', component: L2Deposits, layout: LayoutDefault },
    // { path: '/l2-txn-batches', component: L2TxnBatches, layout: LayoutDefault },
    // { path: '/l2-output-roots', component: L2OutputRoots, layout: LayoutDefault },
    // { path: '/l2-withdrawals', component: L2Withdrawals, layout: LayoutDefault },
    
    { path: '/', component: Home, layout: LayoutHome },
    { path: '/404', component: AppError, layout: LayoutError },
    { path: '/apps', component: Apps, layout: LayoutDefault},
    { path: '/apps/:id', component: MarketPlaceApp, layout: LayoutDefault},
    { path: '/api-docs', component: SwaggerUI, layout: LayoutDefault },
    { path: '/accounts', component: Accounts, layout: LayoutDefault },
    { path: '/address/:hash', component: Address, layout: LayoutDefault },
    { path: '/address/:hash/contract-verification', component: ContractVerification, layout: LayoutDefault },
    { path: '/blocks', component: Blocks, layout: LayoutDefault },
    { path: '/block/:height_or_hash', component: Block, layout: LayoutDefault },
    { path: '/csv-export', component: CsvExport, layout: LayoutDefault },
    { path: '/graphiql', component: GraphQL, layout: LayoutDefault },
    { path: '/search-results', component: SearchResults, layout: LayoutSearchResults },
    { path: '/stats', component: Stats, layout: LayoutDefault },
    { path: '/visualize/sol2uml', component: Sol2Uml, layout: LayoutDefault },
    { path: '/tokens', component: Tokens, layout: LayoutDefault },
    { path: '/token/:hash', component: Token, layout: LayoutDefault },
    { path: '/token/:hash/instance/:id', component: TokenInstance, layout: LayoutDefault },
    { path: '/txs', component: Transactions, layout: LayoutDefault },
    { path: '/tx/:hash', component: Transaction, layout: LayoutDefault },
    { path: '/txs/kettle/:hash', component: KettleTxs, layout: LayoutDefault },
    { path: '/verified-contracts', component: VerifiedContracts, layout: LayoutDefault },
    { path: '/withdrawals', component: Withdrawals, layout: LayoutDefault },
    { path: '*', component: AppError, layout: LayoutError },
    // component: AppRedirect, layout: LayoutDefault },
]
