import { LinkOverlay } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import route from 'lib/router/getRoute';

type Props = {
  id: string;
  url: string;
  external?: boolean;
  title: string;
}

const MarketplaceAppCardLink = ({ url, external, id, title }: Props) => {
  return external ? (
    <LinkOverlay href={ url } isExternal={ true }>
      { title }
    </LinkOverlay>
  ) : (
    <Link to={ route({ pathname: '/apps/[id]', query: { id } }) }>
      <LinkOverlay>
        { title }
      </LinkOverlay>
    </Link>
  );
};

export default MarketplaceAppCardLink;
