import React from 'react';
import { useParams } from 'react-router-dom';

import { TX } from 'stubs/tx';
import { generateListStub } from 'stubs/utils';
import AddressEntity from 'ui/shared/entities/address/AddressEntity';
import PageTitle from 'ui/shared/Page/PageTitle';
import useQueryWithPages from 'ui/shared/pagination/useQueryWithPages';
import TxsContent from 'ui/txs/TxsContent';

const KettleTxs = () => {
  const { hash } = useParams();

  const query = useQueryWithPages({
    resourceName: 'txs_execution_node',
    pathParams: { hash },
    options: {
      placeholderData: generateListStub<'txs_execution_node'>(TX, 50, { next_page_params: {
        block_number: 9005713,
        index: 5,
        items_count: 50,
        filter: 'validated',
      } }),
    },
  });

  return (
    <>
      <PageTitle title="Computor transactions" withTextAd/>
      <AddressEntity address={{ hash }} mb={ 6 }/>
      <TxsContent
        query={ query }
        showSocketInfo={ false }
      />
    </>
  );
};

export default KettleTxs;
