import type { ChakraProps } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

import config from 'configs/app'
import useQueryClientConfig from 'lib/api/useQueryClientConfig';
// import { AppContextProvider } from 'lib/contexts/app';
import { ChakraProvider } from 'lib/contexts/chakra';
import { ScrollDirectionProvider } from 'lib/contexts/scrollDirection';
import { SocketProvider } from 'lib/socket/context';
import theme from 'theme';
import AppErrorBoundary from 'ui/shared/AppError/AppErrorBoundary';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import 'lib/setLocale';
import { routes } from 'src/routes';
import AppInitializer from 'src/components/AppInitializer';

const ERROR_SCREEN_STYLES: ChakraProps = {
  h: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: 'fit-content',
  maxW: '800px',
  margin: '0 auto',
  p: { base: 4, lg: 0 },
};

function MyApp() {
  const queryClient = useQueryClientConfig();

  const handleError = React.useCallback((error: Error) => {
    Sentry.captureException(error);
  }, []);

  return (
    <Router>
        <AppInitializer />
        <ChakraProvider theme={ theme }>
            <AppErrorBoundary
                { ...ERROR_SCREEN_STYLES }
                onError={ handleError }
            >
                {/* <AppContextProvider> */}
                <QueryClientProvider client={ queryClient }>
                    <ScrollDirectionProvider>
                    <SocketProvider url={ `${ config.api.socket }${ config.api.basePath }/socket/v2` }>
                        <Routes>
                            {routes.map((r, i)=> {
                                const Component = r.component
                                const hasLayout = r.layout !== undefined
                                if (hasLayout) {
                                    const Layout = r.layout
                                    return <Route key={`r-${i.toString()}`} path={r.path} element={<Layout><Component routePath={r.path}/></Layout>}/>    
                                }                        
                                return <Route key={`r-${i.toString()}`} path={r.path} element={<Component routePath={r.path}/>}/>
                            })}
                        </Routes>
                    </SocketProvider>
                    </ScrollDirectionProvider>
                    <ReactQueryDevtools buttonPosition="bottom-left" position="left"/>
                </QueryClientProvider>
                {/* </AppContextProvider> */}
            </AppErrorBoundary>
        </ChakraProvider>
    </Router>
  );
}

export default MyApp;
