// https://unicode-table.com
export const asymp = String.fromCharCode(8776); // ~
export const hellip = String.fromCharCode(8230); // …
export const nbsp = String.fromCharCode(160); // no-break Space
export const thinsp = String.fromCharCode(8201); // thin Space
export const space = String.fromCharCode(32); // space
export const nbdash = String.fromCharCode(8209); // non-breaking hyphen
export const mdash = String.fromCharCode(8212); // em dash
export const ndash = String.fromCharCode(8211); // en dash
export const laquo = String.fromCharCode(171); // «
export const raquo = String.fromCharCode(187); // »
export const middot = String.fromCharCode(183); // ·
export const blackCircle = String.fromCharCode(9679); // ●
export const blackRightwardsArrowhead = String.fromCharCode(10148); // ➤
export const degree = String.fromCharCode(176); // °
export const times = String.fromCharCode(215); // ×
export const disk = String.fromCharCode(8226); // •
export const minus = String.fromCharCode(8722); // −
export const leftLineArrow = String.fromCharCode(8592); // ←
export const rightLineArrow = String.fromCharCode(8594); // →
export const apos = String.fromCharCode(39); // apostrophe '
export const shift = String.fromCharCode(8679); // upwards white arrow ⇧
export const cmd = String.fromCharCode(8984); // place of interest sign ⌘
export const alt = String.fromCharCode(9095); // alternate key symbol ⎇
